(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-table-row.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-table-row.js');
"use strict";


const {
  clx
} = svs.components.lbUtils;
const {
  DrawCard,
  useConditionalWrapper
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_LAYOUTS,
  DRAW_CARD_ICON_SIZE,
  DRAW_STATE,
  DRAW_STATE_TEXTS,
  DRAW_CARD_TITLES
} = svs.components.tipsen.drawCard.constants;
const {
  DisabledOverlay,
  DrawCardPanel
} = svs.components.tipsen.drawCard.components;
const {
  SpeechBubble,
  SpeechBubbleSport3
} = svs.ui.ReactSpeechBubble;
const DrawCardTableRowCell = _ref => {
  let {
    children,
    className
  } = _ref;
  return React.createElement("div", {
    className: "pg_draw_card_table_row__cell ".concat(className || '').trim(),
    role: "cell"
  }, children);
};
const DisabledOverlayWrapper = _ref2 => {
  let {
    children
  } = _ref2;
  return React.createElement(DisabledOverlay, {
    isHorizontal: true
  }, children);
};
const DrawCardTableRow = _ref3 => {
  let {
    betEvents,
    branding,
    className,
    competitionName,
    extraMoney,
    icon = 'game-sport',
    league,
    netSale,
    regCloseTime,
    state,
    title,
    uniqueId,
    isSelected
  } = _ref3;
  const blockName = 'pg_draw_card_table_row';
  const classNames = clx(blockName, {
    'pg_draw_card--selected': isSelected,
    'pg_draw_card_table_row--selected': isSelected
  }, className);
  const ConditionalDisabledOverlayWrapper = useConditionalWrapper(DisabledOverlayWrapper, {
    skip: state !== DRAW_STATE.TEMPORARY_CLOSED
  });
  return React.createElement(ConditionalDisabledOverlayWrapper, null, React.createElement(DrawCard, {
    branding: branding,
    className: classNames,
    layout: DRAW_CARD_LAYOUTS.HORIZONTAL,
    waiAriaRole: "row"
  }, React.createElement(DrawCardTableRowCell, {
    className: "pg_draw_card_table_row__title"
  }, React.createElement("div", {
    className: "pg_draw_card_table_row__title--draw_league"
  }, React.createElement(DrawCard.Title, {
    icon: icon,
    iconSize: DRAW_CARD_ICON_SIZE.SMALL,
    isSelected: isSelected
  }, competitionName ? competitionName : title), React.createElement("span", {
    className: "pg_draw_card_table_row__title__league"
  }, league)), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoney) && React.createElement(SpeechBubble, {
    size: "100"
  }, React.createElement(SpeechBubbleSport3, null, "".concat(DRAW_CARD_TITLES.EXTRA_MONEY, ": ").concat(extraMoney.amount))), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isRolloverIn) && React.createElement(SpeechBubble, {
    size: "100"
  }, React.createElement(SpeechBubbleSport3, null, "".concat(DRAW_CARD_TITLES.ROLL_OVER_IN, ": ").concat(extraMoney.amount)))), React.createElement(DrawCardTableRowCell, {
    className: "pg_draw_card_table_row__reg_close_time"
  }, state !== DRAW_STATE.CANCELLED && React.createElement(DrawCardPanel, {
    title: DRAW_CARD_TITLES.BET_STOP
  }, React.createElement(DrawCard.RegCloseTime, null, regCloseTime)), state === DRAW_STATE.CANCELLED && React.createElement(DrawCard.Status, {
    isCancelled: true
  }, DRAW_STATE_TEXTS.CANCELLED)), React.createElement(DrawCardTableRowCell, {
    className: "pg_draw_card_table_row__net_sale"
  }, React.createElement(DrawCardPanel, {
    title: DRAW_CARD_TITLES.NET_SALE
  }, React.createElement(DrawCard.NetSale, {
    uniqueId: uniqueId
  }, netSale))), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoney) && (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isJackpot) && React.createElement(DrawCardTableRowCell, {
    className: "pg_draw_card_table_row__extra_money"
  }, React.createElement(DrawCardPanel, null, React.createElement(DrawCard.ExtraAmount, null, "".concat(DRAW_CARD_TITLES.JACKPOT, ": ").concat(extraMoney.amount)))), React.createElement(DrawCardPanel, {
    className: "pg_draw_card_table_row__bet_events"
  }, React.createElement(DrawCard.BetEvents, {
    hasScrolling: true
  }, betEvents)), React.createElement(DrawCard.DrawToggleBtn, {
    className: "pg_draw_card_table_row__change_draw_btn",
    isSelected: isSelected
  })));
};
setGlobal('svs.components.tipsen.drawCard.DrawCardTableRow', DrawCardTableRow);

 })(window);