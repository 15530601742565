(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card.js');
"use strict";


const {
  useMemo
} = React;
const {
  clx
} = svs.components.lbUtils;
const {
  DRAW_CARD_LAYOUTS
} = svs.components.tipsen.drawCard.constants;
const {
  DrawCardContext,
  withinDrawCardContext
} = svs.components.tipsen.drawCard.context;
const {
  DrawCardExtraAmount,
  DrawCardLeague,
  DrawCardNetSale,
  DrawCardBetEvents,
  DrawCardToggleBtn,
  DrawCardRegCloseTime,
  DrawCardStatus,
  DrawCardTitle,
  DrawCardChangeDraw
} = svs.components.tipsen.drawCardComponents;
const DrawCard = _ref => {
  let {
    children,
    className,
    layout = DRAW_CARD_LAYOUTS.VERTICAL,
    waiAriaRole,
    branding
  } = _ref;
  const context = useMemo(() => ({
    layout
  }), [layout]);
  const blockName = 'pg_draw_card';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--").concat(branding)]: branding,
    ["".concat(blockName, "--horizontal")]: layout === DRAW_CARD_LAYOUTS.HORIZONTAL
  }, className);
  return React.createElement("div", {
    className: classNames,
    role: waiAriaRole
  }, React.createElement(DrawCardContext.Provider, {
    value: context
  }, children));
};
DrawCard.ExtraAmount = withinDrawCardContext(DrawCardExtraAmount);
DrawCard.League = withinDrawCardContext(DrawCardLeague);
DrawCard.NetSale = withinDrawCardContext(DrawCardNetSale);
DrawCard.BetEvents = withinDrawCardContext(DrawCardBetEvents);
DrawCard.DrawToggleBtn = withinDrawCardContext(DrawCardToggleBtn);
DrawCard.RegCloseTime = withinDrawCardContext(DrawCardRegCloseTime);
DrawCard.Status = withinDrawCardContext(DrawCardStatus);
DrawCard.Title = withinDrawCardContext(DrawCardTitle);
DrawCard.ChangeDraw = withinDrawCardContext(DrawCardChangeDraw);
setGlobal('svs.components.tipsen.drawCard.DrawCard', DrawCard);

 })(window);